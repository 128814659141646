import {AuthenticationProviderService} from '../authentication-provider-service-impl.service';
import {Observable, of} from 'rxjs';
import {AuthenticationProviderConfig} from '../../models/authentication-provider-config';

export class AuthenticationProviderServiceMock implements AuthenticationProviderService {
    readonly authenticationProvider$: Observable<AuthenticationProviderConfig[]> = of([
        {
            id: 1,
            title: 'Mock auth provider',
            type: 'mock',
            clientId: 'mock',
            scopes: [],
            brandingTextColor: '#000',
            brandingFirstColor: '#DDD',
            brandingSecondColor: '#999'
        }
    ]);

    initialize(): Promise<void> {
        return Promise.resolve(undefined);
    }

}
