import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {

    private languages: { [lang: string]: object; } | undefined;
    private currentLanguage: string | undefined;
    private onChangeSubject = new Subject<string>();

    constructor(
        private translateService: TranslateService,
    ) {
    }

    public init(languages: { [language: string]: object }): void {
        this.languages = languages;
        this.translateService.addLangs(Object.keys(this.languages));
        this.detectLanguageAndSet();
    }

    public async set(language: string): Promise<void> {
        // When not available the first added language is the fallback
        language = this.getAvailableLanguages().includes(language) ? language : this.getAvailableLanguages()[0];

        if (this.currentLanguage !== language && this.languages) {
            this.currentLanguage = language;
            this.translateService.setDefaultLang(language);
            this.translateService.setTranslation(language, this.languages[language]);
            this.translateService.use(language);

            // Broadcast the changed language
            this.onChangeSubject.next(language);
        }
    }
    public getAvailableLanguages(): string[] {
        return this.translateService.getLangs();
    }

    private async detectLanguageAndSet(): Promise<void> {
        await this.set('nl');
    }
}
