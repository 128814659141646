import {ProjectServiceInterface} from '../project.service';
import {firstValueFrom, Observable} from 'rxjs';
import {Project} from '../../models/project';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {map} from 'rxjs/operators';

export const mockProjects: Array<Project> = [
    {
        id: 1,
        name: 'Stationstraat lantaarnpalen',
        requireVerification: false,
        code: 'P001',
        mapLayersData: [],
        configurationUrl: null,
        executorCanCreateObjects: true,
    },
    {
        id: 2,
        name: 'Kanaal Almelo',
        requireVerification: false,
        code: 'P002',
        mapLayersData: [],
        configurationUrl: null,
        executorCanCreateObjects: false,
    }
];

export class ProjectServiceMock extends AbstractCrudServiceMock<Project> implements ProjectServiceInterface {

    constructor() {
        super(mockProjects);
    }

    list(_: string): Observable<Project[]> {
        return this.getAll().pipe(
            map(response => response.content)
        );
    }

    get(id: number): Promise<Project> {
        return firstValueFrom(super.getImpl(id));
    }

    syncProjects(): Promise<void> {
        return Promise.resolve(undefined);
    }
}
