import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {ProjectForm} from '../../models/project-form';
import {ProjectFormService} from '../project-form.service';
import {Injectable} from '@angular/core';


const mockProjectForms: ProjectForm[] = [
    {
        id: 1,
        title: 'Betonnering Geesteren',
        paulaObjectTypes: [{id: 1, title: 'type'}],
        requiredJobTitleSuffixLabel: null,
        requiredJobTitleSuffixHelpText: null
    },
    {
        id: 2,
        title: 'Asfaltering Rijssen',
        paulaObjectTypes: [{id: 1, title: 'type'}],
        requiredJobTitleSuffixLabel: null,
        requiredJobTitleSuffixHelpText: null
    },
];

@Injectable()
export class ProjectFormServiceMock implements ProjectFormService {
    list(): Observable<PageResponse<ProjectForm>> {

        const result: PageResponse<ProjectForm> = {
            content: mockProjectForms,
            empty: false,
            first: true,
            last: true,
            number: 0,
            numberOfElements: mockProjectForms.length,
            size: 25,
            totalElements: mockProjectForms.length,
            totalPages: 1
        };

        return of(result);
    }

    get(project: number, id: number): Observable<ProjectForm> {
        const form = mockProjectForms.find(item => item.id == id);
        if (form == undefined) {
            throw new Error(`There is no form defined with id: ${id}`);
        }

        return of(form);
    }
}
