export const TRANSLATION_NL = {
    COMPONENTS: {
        IMAGE_ANNOTATION: {
            button: {
                add: 'Toevoegen',
                cancel: 'Annuleren',
            },
            textfield: 'Tekst toevoegen',
            COMMENT: {
                FIELD: {
                    header: 'Opmerking toevoegen',
                    description: 'Voeg een opmerking toe aan een afbeelding.',
                    label: 'Antwoord',
                },
                button: {
                    add: 'Toevoegen',
                    cancel: 'Annuleren',
                },
            }
        },
        QUESTION_TYPE_SIGNATURE: {
            summary: {
                description: 'Hieronder zie je de gegeven antwoorden. ' +
                    'Door uw handtekening te zetten gaat u akkoord met de gegeven antwoorden.',
                answers: 'Uw antwoorden',
                addsignature: 'Tik om uw handtekening te zetten'
            }
        },
        LOGOUT: {
            button: {
                logout: 'Uitloggen',
            }
        },
        QUESTION_DESCRIPTION: {
            more_info: 'Meer informatie'
        }
    },
    PAGE_LOGIN: {
        button: {
            login: 'Inloggen',
        },
        link: {
            faq: 'Veelgestelde vragen',
        },
    },
    PAGE_FAQ: {
        title: 'Veelgestelde vragen',
        previous: 'Vorige',
        contact: {
            name: 'ICT helpdesk',
            email: 'occ@vwtelecom.com',
            subject: 'Ik heb een vraag over de app {{appName}} (versie {{appVersion}})',
            toast: 'Stuur een e-mail naar:',
        },
        items: [{
            question: 'Hoe kan ik inloggen?',
            answer: 'Je logt in met je gebruikersnaam en wachtwoord',
        }, {
            question: 'Ik kan niet inloggen, wat nu?',
            answer: 'Controleer of je verbonden bent met het internet. Indien het probleem blijft, neem contact op met de ICT helpdesk.',
        }, {
            question: 'Bij wie kan ik terecht voor technische vragen over de app?',
            answer: 'Voor technische vragen over de app kun je contact opnemen met de ICT helpdesk.',
        }, {
            question: 'Kan ik offline werken met de app?',
            answer: 'Nee, voor het gebruik van de app heb je een actieve internetverbinding nodig.',
        }, {
            question: 'Mijn vraag staat er niet tussen',
            answer: 'Je kunt met je vragen terecht bij de ICT helpdesk.',
        }],
    },
    TOAST: {
        form_sync_success: 'Formulier succesvol verstuurd',
        form_background_sync_success: 'Het formulier wordt automatisch verstuurd zodra er een internetverbinding is',
        image_sync_error: 'Een of meerdere afbeeldingen konden niet geupload worden. Probeer het later nog eens',
    },
    FORM_QUESTION_LOCATION: {
        form_question_location_notice: 'Tik op de kaart om de locatie van het object te bepalen ' +
            'of gebruik uw eigen huidige locatie.',
        form_question_location_description_location: 'Omschrijving locatie',
        form_question_location_description_location_from: 'Omschrijving locatie van',
        form_question_location_description_location_until: 'Omschrijving locatie tot',
        form_question_location_description: 'Omschrijving',
        form_question_location_use_gps: 'GPS'
    },
    FORM_QUESTION_TYPE_LOCATION: {
        from: 'Van locatie',
        to: 'Tot locatie',
        current: 'Huidige locatie',
    }
};
