import {PageResponse} from '../../models/page-response';
import {Observable, of} from 'rxjs';
import {Identifiable} from '../../models/identifiable';
import {tap} from 'rxjs/operators';

export class AbstractCrudServiceMock<T extends Identifiable> {

    constructor(protected data: Array<T>) {
    }

    protected getAll() {
        const result: PageResponse<T> = {
            content: this.data,
            empty: false,
            first: true,
            last: true,
            number: 0,
            numberOfElements: this.data.length,
            size: 25,
            totalElements: this.data.length,
            totalPages: 1
        };

        return of(result);
    }

    protected getImpl(id: number): Observable<T> {
        const result = this.data.find(item => +item.id === +id);
        if (result === undefined) {
            throw new Error(`No item found for id '${id}'`);
        }
        return of(result);
    }

    protected post(newData: T) {
        newData.id = this.nextId();
        this.data.push(newData);

        return this.getImpl(newData.id);
    }

    protected put(newData: Partial<T>): Observable<T> {
        if (newData.id === undefined) {
            throw new Error(`Property id is missing and required`);
        }

        return this.getImpl(newData.id).pipe(
            tap(item => {
                Object.assign(item, newData);
            })
        );
    }

    protected delete(data: T): Observable<T> {
        this.data = this.data.filter(question => +question.id !== +data.id);
        return of(data);
    }

    protected nextId(): number {
        const highestId = this.data.reduce((highest, item) => {
            if (item.id > highest) {
                return item.id;
            }

            return highest;
        }, 0);

        return highestId + 1;
    }
}
