import {firstValueFrom, Observable, Subject} from 'rxjs';
import {ProjectJobAnswer} from '../models/project-job-answer';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ProjectJobAnswerMeta} from '../models/project-job-answer-meta';
import {FileStorageService} from './file-storage.service';
import {ImageToolConfiguration} from '../models/image-tool-configuration';
import {FormService} from './form.service';
import {FormUtils} from '../utils/form-utils';
import {MetadataAnswerProperties} from '../models/metadata-answer-properties';
import {AnnotateToolConfiguration} from '../models/annotate-tool-configuration';

export interface ProjectJobAnswerMetaService {
    metadataUpdated$: Subject<string>;
    postProjectJobAnswerMeta(answer: MetadataAnswerProperties, meta: ProjectJobAnswerMeta[]): Observable<ProjectJobAnswerMetaApiObject>;
    getProjectJobAnswerMeta(answer: MetadataAnswerProperties): Observable<ProjectJobAnswerMetaApiObject>;
    findProjectJobAnswerMeta(answer: MetadataAnswerProperties): Promise<ProjectJobAnswerMeta[]>;
    findLocalProjectJobAnswerMeta(answer: MetadataAnswerProperties): Promise<ProjectJobAnswerMeta[]>;
    updateLocalProjectJobAnswerMeta(answer: MetadataAnswerProperties, newAnswerMeta: ProjectJobAnswerMeta): Promise<void>;
    removeLocalMetadataFromCurrentQuestion(jobId: number, questionPosition: number, answerId: string): Promise<void>;
    replaceCurrentQuestionAnswerMetaId(
        jobId: number,
        questionPosition: number,
        oldAnswerId: string,
        newAnswerId: string
    ): Promise<void>;
    isDanglingImage(jobId: number, questionPosition: number, answerId: string): Promise<boolean>;
    clearLocalMetadata(answer: MetadataAnswerProperties): Promise<void>;
    findImageTools(projectId: number, jobId: number): Promise<ImageToolConfiguration>;
    findAnnotateTools(projectId: number, jobId: number): Promise<AnnotateToolConfiguration>;
    answerHasCommentOnCurrentQuestion(jobId: number, questionPosition: number, answerUuid: string): Promise<boolean>;
    getCompleteProjectJobAnswerMeta(projectJobId: number): Promise<ProjectJobAnswerMeta[]>;
    addExifForUuid(
        jobId: number,
        questionPosition: number,
        answerUuid: string,
        exif: {[key: string]: string}
    ): Promise<void>;
    getCurrentAnswerForFilename(jobId: number, questionPosition: number): Promise<MetadataAnswerProperties | null>;
}

export interface ProjectJobAnswerMetaApiObject {
    items: {answer_id: string, history: string, photo_id: string, comment: string | null, position: number, file_created_at: string | null}[];
}

// TODO: Make meta support for layer forms if customer requests it
@Injectable()
export class ProjectJobAnswerMetaServiceImpl implements ProjectJobAnswerMetaService {

    constructor(private httpClient: HttpClient,
                private formService: FormService,
                private fileStorageService: FileStorageService
    ) {
    }

    // We need to keep a reference to the current answer
    // As we need the reference to the project and the job to get the metadata
    metadataUpdated$: Subject<string> = new Subject<string>();

    postProjectJobAnswerMeta(answer: ProjectJobAnswer,
                             meta: ProjectJobAnswerMeta[]): Observable<ProjectJobAnswerMetaApiObject> {
        return this.httpClient.post<ProjectJobAnswerMetaApiObject>(
            `/app-api/v1/answers/${answer.id}/meta`,
            {items: meta.map((metadata) => {
                return {
                    answer_id: metadata.answer_id,
                    history: JSON.stringify(metadata.history),
                    photo_id: metadata.photo_id,
                    file_created_at: metadata.file_created_at,
                    comment: metadata.comment
                };
            })}
        );
    }

    getProjectJobAnswerMeta(answer: ProjectJobAnswer): Observable<ProjectJobAnswerMetaApiObject> {
        return this.httpClient.get<ProjectJobAnswerMetaApiObject>(
            `/app-api/v1/answers/${answer.id}/meta`
        );
    }

    async getCompleteProjectJobAnswerMeta(projectJobId: number): Promise<ProjectJobAnswerMeta[]> {
        try {
            const projectJobAnswerMetaList = await firstValueFrom(this.httpClient.get<ProjectJobAnswerMetaApiObject>(
                `/app-api/v1/answers/${projectJobId}/meta/list`
            ));
            this.persistFullProjectJobAnswerMetaData(projectJobId, projectJobAnswerMetaList);

            return projectJobAnswerMetaList.items.map((apiobject) => {
                return {
                    answer_id: apiobject.answer_id,
                    job: projectJobId,
                    photo_id: apiobject.photo_id,
                    history: typeof apiobject.history === 'string' ? JSON.parse(apiobject.history) : apiobject.history,
                    comment: apiobject.comment,
                    file_created_at: apiobject.file_created_at,
                    position: apiobject.position
                };
            });
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.status === 404) {
                // Do nothing, 404 is normal here
                // TODO: Refactor this meta logic to avoid 404 status
                return [];
            } else {
                throw error;
            }
        }
    }

    async findProjectJobAnswerMeta(answer: ProjectJobAnswer): Promise<ProjectJobAnswerMeta[]> {
        let projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(answer);

        // In case our job answer has an identifier, retrieve the meta data from the server if we do not have any data locally
        // If there is local data, we will always trust that over the server data which might not be up to date
        if (projectJobAnswerMeta.length === 0 && answer.id) {
            const projectJobAnswerMetaApiObject = await firstValueFrom(this.getProjectJobAnswerMeta(answer));
            projectJobAnswerMeta = projectJobAnswerMetaApiObject.items.map((apiobject) => {
                return {
                    answer_id: apiobject.answer_id,
                    job: answer.job,
                    photo_id: apiobject.photo_id,
                    history: JSON.parse(apiobject.history),
                    comment: apiobject.comment,
                    position: apiobject.position,
                    file_created_at: apiobject.file_created_at
                };
            });

            if (projectJobAnswerMeta.length > 0) {
                await this.persistCompleteProjectJobAnswerMeta(answer, projectJobAnswerMeta);
            }
        }

        return projectJobAnswerMeta;
    }

    public async findLocalProjectJobAnswerMeta(answer: MetadataAnswerProperties): Promise<ProjectJobAnswerMeta[]> {
        const metadataFilename = this.getAnswerMetadataFilename(answer);
        return this.fileStorageService.checkTxtFileExists(metadataFilename).then(async (answerExists) => {
            let projectJobAnswerMetadata: ProjectJobAnswerMeta[] = [];
            if (answerExists) {
                projectJobAnswerMetadata = await this.fileStorageService.readTxtFileAsJson<ProjectJobAnswerMeta[]>(metadataFilename);
            }

            return projectJobAnswerMetadata;
        });
    }

    public async updateLocalProjectJobAnswerMeta(answer: MetadataAnswerProperties, newAnswerMeta: ProjectJobAnswerMeta) {
        const projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(answer);
        let answerMetaIndex = projectJobAnswerMeta.findIndex((value) => {
            return value.answer_id === newAnswerMeta.answer_id;
        });

        // In case no index is found, append a new entry
        if (answerMetaIndex === -1) {
            answerMetaIndex = projectJobAnswerMeta.length;
        } else {
            // Do not replace the original photo uuid or the file creation date
            newAnswerMeta.photo_id = projectJobAnswerMeta[answerMetaIndex].photo_id;
            newAnswerMeta.file_created_at = projectJobAnswerMeta[answerMetaIndex].file_created_at;
        }

        projectJobAnswerMeta[answerMetaIndex] = newAnswerMeta;
        await this.persistCompleteProjectJobAnswerMeta(answer, projectJobAnswerMeta);
        this.metadataUpdated$.next(newAnswerMeta.photo_id);
    }

    public async clearLocalMetadata(answer: ProjectJobAnswer) {
        await this.fileStorageService.removeTxtFile(this.getAnswerMetadataFilename(answer));
    }

    public async replaceCurrentQuestionAnswerMetaId(
        jobId: number,
        questionPosition: number,
        oldAnswerId: string,
        newAnswerId: string
    ) {
        const answer = await this.getCurrentAnswerForFilename(jobId, questionPosition);

        if (answer) {
            const projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(answer);
            const answerMetaIndex = projectJobAnswerMeta.findIndex((value) => {
                return value.answer_id === oldAnswerId;
            });

            // Only replace the element if the answer was found
            if (answerMetaIndex !== -1) {
                projectJobAnswerMeta[answerMetaIndex].answer_id = newAnswerId;

                await this.persistCompleteProjectJobAnswerMeta(answer, projectJobAnswerMeta);
            }
        }
    }

    public async removeLocalMetadataFromCurrentQuestion(jobId: number, questionPosition: number,answerUuid: string) {
        const answer = await this.getCurrentAnswerForFilename(jobId, questionPosition);

        if (answer) {
            const projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(answer);
            const answerMetaIndex = projectJobAnswerMeta.findIndex((value) => {
                return value.answer_id === answerUuid;
            });

            // Only remove the element if the answer was found
            if (answerMetaIndex !== -1) {
                projectJobAnswerMeta.splice(answerMetaIndex, 1);
            }

            await this.persistCompleteProjectJobAnswerMeta(answer, projectJobAnswerMeta);
        }
    }

    public async isDanglingImage(jobId: number, questionPosition: number, answerId: string): Promise<boolean> {
        const answer = await this.getCurrentAnswerForFilename(jobId, questionPosition);

        if (answer) {
            const projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(answer);
            const answerMetaIndex = projectJobAnswerMeta.findIndex((value) => {
                return value.answer_id === answerId || value.photo_id === answerId;
            });

            return answerMetaIndex === -1;
        } else {
            return true;
        }
    }

    /**
     * Add the EXIF DateTimeOriginal field to the answer metadata
     * If the answer metadata does not exist, add it
     */
    public async addExifForUuid(
        jobId: number,
        questionPosition: number,
        answerUuid: string,
        exif: { [key: string]: string } = {}
    ) {

        const metadataAnswerProperties = await this.getCurrentAnswerForFilename(jobId, questionPosition);
        if (metadataAnswerProperties !== null) {
            const projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(metadataAnswerProperties);
            const metaIndex = projectJobAnswerMeta.findIndex((value) => {
                return value.answer_id === answerUuid;
            });

            let meta: ProjectJobAnswerMeta;
            if (metaIndex === -1 && metadataAnswerProperties) {
                meta = {
                    answer_id: answerUuid,
                    history: [],
                    photo_id: answerUuid,
                    comment: null,
                    file_created_at: null,
                    job: metadataAnswerProperties.job,
                    position: metadataAnswerProperties.position
                };
            } else {
                meta = projectJobAnswerMeta[metaIndex];
            }

            if (meta) {
                const exifDateTimeRegex = /(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})/;

                ['DateTime', 'DateTimeOriginal'].forEach((property) => {
                    if (exif && property in exif && exif[property] !== null) {
                        try {
                            const matches = exifDateTimeRegex.exec(exif[property]);
                            if (matches !== null) {
                                meta.file_created_at = (new Date(
                                    parseInt(matches[1], 10),
                                    parseInt(matches[2], 10) - 1,
                                    parseInt(matches[3], 10),
                                    parseInt(matches[4], 10),
                                    parseInt(matches[5], 10),
                                    parseInt(matches[6], 10)
                                )).toISOString();
                            }
                        } catch (e) {
                            console.error('Invalid ' + property + ' format', exif, e);
                        }
                    }
                });

                if (metaIndex === -1) {
                    projectJobAnswerMeta.push(meta);
                } else {
                    projectJobAnswerMeta[metaIndex] = meta;
                }
                this.persistCompleteProjectJobAnswerMeta(metadataAnswerProperties, projectJobAnswerMeta);
            }
        }
    }

    async findImageTools(projectId: number, jobId: number): Promise<ImageToolConfiguration> {
        const imageToolFilename = 'current-image-tools';
        const imageTools = await this.fileStorageService.checkTxtFileExists(imageToolFilename).then(async (fileExists) => {
            let localImageTools: ImageToolConfiguration = {
                palette: [],
                tools: []
            };

            if (fileExists) {
                localImageTools = await this.fileStorageService.readTxtFileAsJson<ImageToolConfiguration>(imageToolFilename);
            }

            return localImageTools;
        });

        // Retrieve the sketchset from the server
        return firstValueFrom(this.httpClient.get<ImageToolConfiguration>(
                `/app-api/v1/projects/${projectId}/forms/${jobId}/sketchset`
        )).then(async (sketchSet) => {
            // Persist the sketch set locally so we can always fall back on it during connectivity issues
            await this.fileStorageService.storeTxtFile(imageToolFilename, JSON.stringify(sketchSet) );
            return sketchSet;
        })
        // If no sketchset could be found - Or if there are connection issues, revert to the locally stored image tools
        // As a fallback to atleast allow editting
        .catch((error) => {
            console.warn('Could not load sketchset - Using locally stored sketchset', error);
            return imageTools;
        });
    }

    async findAnnotateTools(projectId: number, jobId: number): Promise<AnnotateToolConfiguration> {
        const imageToolFilename = 'current-image-tools';
        const imageTools = await this.fileStorageService.checkTxtFileExists(imageToolFilename).then(async (fileExists) => {
            let localImageTools: AnnotateToolConfiguration = {
                palette: [],
                tools: []
            };

            if (fileExists) {
                localImageTools = await this.fileStorageService.readTxtFileAsJson<AnnotateToolConfiguration>(imageToolFilename);
            }

            return localImageTools;
        });

        // Retrieve the sketchset from the server
        return firstValueFrom(this.httpClient.get<AnnotateToolConfiguration>(
            `/app-api/v1/projects/${projectId}/forms/${jobId}/sketchset`
        )).then(async (sketchSet) => {
            // Persist the sketch set locally so we can always fall back on it during connectivity issues
            await this.fileStorageService.storeTxtFile(imageToolFilename, JSON.stringify(sketchSet) );
            return sketchSet;
        })
            // If no sketchset could be found - Or if there are connection issues, revert to the locally stored image tools
            // As a fallback to atleast allow editting
            .catch((error) => {
                console.warn('Could not load sketchset - Using locally stored sketchset', error);
                return imageTools;
            });
    }

    public async answerHasCommentOnCurrentQuestion(jobId: number, questionPosition: number, answerUuid: string): Promise<boolean> {
        const answer = await this.getCurrentAnswerForFilename(jobId, questionPosition);

        if (answer) {
            const projectJobAnswerMeta = await this.findLocalProjectJobAnswerMeta(answer);
            return !!projectJobAnswerMeta.find((value) => {
                return value.answer_id === answerUuid && !!value.comment;
            });
        } else {
            return false;
        }
    }

    async getCurrentAnswerForFilename(jobId: number, questionPosition: number): Promise<MetadataAnswerProperties | null> {
        const form = await firstValueFrom(this.formService.getJob(jobId));
        let minimalAnswerProperties = null;

        if (form) {
            if (form.type !== 'jobForm') {
                // TODO: Meta support for layer forms?
                console.error('Can only get metadata for job forms');
                return null;
            }

            const answer = FormUtils.getLatestAnswer(form, questionPosition, undefined);
            const question = FormUtils.getQuestionByPosition(form, questionPosition);
            if ( answer || question ) {
                minimalAnswerProperties = {
                    id: answer ? answer.id : null,
                    // TODO: Should this contain nodeId too?
                    position: answer ? answer.position : question.position,
                    job: form.id,
                };
            }
        }

        return minimalAnswerProperties
    }

    private async persistCompleteProjectJobAnswerMeta(answer: MetadataAnswerProperties, answerMeta: ProjectJobAnswerMeta[]) {
        await this.fileStorageService.storeTxtFile(this.getAnswerMetadataFilename(answer), JSON.stringify(answerMeta));
    }

    private persistFullProjectJobAnswerMetaData(projectJobId: number, projectJobAnswerMetaList: ProjectJobAnswerMetaApiObject) {
        const metadataPerFilename = projectJobAnswerMetaList.items.reduce<{ [key: string]: ProjectJobAnswerMetaApiObject['items'] }>(
            (acc, metadata) => {
                const filename = this.getMetadataFilename(projectJobId, metadata.position);
                if (!acc[filename]) {
                    acc[filename] = [];
                }

                // Make sure the history from the server is persisted locally as a proper object instead of a json string
                metadata.history = JSON.parse(metadata.history);
                acc[filename].push(metadata);
                return acc;
            }, {}
        );

        const persistUnsyncedMetadata = async(filename: string) => {
            if (!await this.fileStorageService.checkTxtFileExists(filename)) {
                await this.fileStorageService.storeTxtFile(filename, JSON.stringify(metadataPerFilename[filename]));
            }
        };

        Object.keys(metadataPerFilename).forEach(persistUnsyncedMetadata);
    }

    private getAnswerMetadataFilename(answer: MetadataAnswerProperties): string {
        return this.getMetadataFilename(answer.job, answer.position);
    }

    private getMetadataFilename(jobId: number, answerPosition: number): string {
        return `${jobId}-${answerPosition}`;
    }
}
