import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {ProjectForm} from '../models/project-form';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

export interface ProjectFormService {
    list(project: number, page: number, paulaObjectType?: number, query?: string): Observable<PageResponse<ProjectForm>>;
    get(project: number, id: number): Observable<ProjectForm>;
}

@Injectable()
export class ProjectFormServiceImpl implements ProjectFormService {
    constructor(private http: HttpClient) {
    }

    list(project: number, page: number, paulaObjectType?: number, query?: string): Observable<PageResponse<ProjectForm>> {
        let params = new HttpParams().set('page', '' + page);

        if (paulaObjectType) {
            params = params.set('search', `paulaObjectTypes.id=${paulaObjectType}`);
        }

        if (query) {
            params = params.append('search', `title:${query}`);
        }

        return this.http.get<PageResponse<ProjectForm>>(`/app-api/v1/projects/${project}/forms`, {params});
    }

    get(project: number, id: number): Observable<ProjectForm> {
        return this.http.get<ProjectForm>(`/app-api/v1/projects/${project}/forms/${id}`);
    }
}
