export const TRANSLATION_EN = {
    COMPONENTS: {
        IMAGE_ANNOTATION: {
            button: {
                add: 'Add',
                cancel: 'Cancel',
            },
            textfield: 'Add text',
            COMMENT: {
                FIELD: {
                    header: 'Add a comment',
                    description: 'Add a comment to an image.',
                    label: 'Answer',
                },
                button: {
                    add: 'Add',
                    cancel: 'Cancel',
                },
            }
        },
        QUESTION_TYPE_SIGNATURE: {
            summary: {
                description: 'Below you can see the answers given. ' +
                    'By signing you agree to the answers given.',
                answers: 'Your answers',
                addsignature: 'Tap to sign'
            }
        },
        LOGOUT: {
            button: {
                logout: 'Logout'
            }
        },
        QUESTION_DESCRIPTION: {
            more_info: 'More information'
        }
    },
    PAGE_LOGIN: {
        button: {
            login: 'Login',
        },
        link: {
            faq: 'Frequently asked questions',
        },
    },
    PAGE_FAQ: {
        title: 'Frequently Asked Questions',
        previous: 'Previous',
        contact: {
            name: 'IT Support team',
            email: 'ict@vwtelecom.com',
            subject: 'I have a question about the app {{appName}} (version {{appVersion}})',
            toast: 'Send an email to:',
        },
        items: [{
            question: 'How do I login?',
            answer: 'You can login using your user name and password. If you don\'t have an account, please contact your HRM team.',
        }, {
            question: 'I cant login',
            answer: 'Please check if you\'re connected to internet. You need an active internet connection.',
        }, {
            question: 'Who can I contact for technical questions about the app?',
            answer: 'For technical questions about the app, please contact our IT Support team.',
        }, {
            question: 'Can I use the expenses app offline?',
            answer: 'No, you need an active internet connection to use the app.',
        }, {
            question: 'Can\'t find what you\'re looking for?',
            answer: 'You can contact our IT Support team.',
        }],
    },
    TOAST: {
        form_sync_success: 'Form sent successfully',
        form_background_sync_success: 'The form will be sent automatically as soon as there is an internet connection',
        image_sync_error: 'One or more images could not be uploaded. Please try again later',
    },
    FORM_QUESTION_LOCATION: {
        form_question_location_notice: 'Tap the map to determine the location of the management object ' +
        'or use your own current location.',
        form_question_location_description_location: 'Description location',
        form_question_location_description_location_from: 'Description location of',
        form_question_location_description_location_until: 'Description of location until',
        form_question_location_description: 'Description',
        form_question_location_use_gps: 'GPS'
    }
};
