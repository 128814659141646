import {inject} from '@angular/core';
import {CanActivateChildFn, Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Network} from '@capacitor/network';

export const ensureAuthenticatedGuardFn: CanActivateChildFn = async () => {
    const authService = inject(AuthenticationService);
    const router = inject(Router);

    try {
        await authService.ensureAuthenticated();
    } catch (e) {
        console.warn('Failed to ensure authentication', e);
        // If online or accessToken doesn't exist redirect to login page
        const networkStatus = await Network.getStatus();
        if (networkStatus.connected || !localStorage.getItem('accessToken')) {
            return router.createUrlTree(['login']);
        }
    }
    return true;

}
