import {PaulaObstructionCode} from '../../models/paula-obstruction-code';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {ObstructionCodeService} from '../obstruction-code.service';
import {Observable} from 'rxjs';
import {PageResponse} from '../../models/page-response';

export const mockObstructionCodes: PaulaObstructionCode[] = [
    {
        id: 1,
        code: 'Niet thuis',
        name: 'Niet thuis',
    }
];

export class ObstructionCodeServiceMock extends AbstractCrudServiceMock<PaulaObstructionCode> implements ObstructionCodeService {
    constructor() {
        super(mockObstructionCodes);
    }

    list(): Observable<PageResponse<PaulaObstructionCode>> {
        return super.getAll();
    }
}
