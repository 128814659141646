import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectJobAttachment} from '../models/project-job-attachment';
import {Observable} from 'rxjs';

export interface ProjectJobAttachmentService {
    getAttachments(projectId: number, formId: number): Observable<ProjectJobAttachment[]>;
}

@Injectable()
export class ProjectJobAttachmentServiceImpl implements ProjectJobAttachmentService {
    constructor(private httpClient: HttpClient) {
    }

    getAttachments(projectId: number, formId: number): Observable<ProjectJobAttachment[]> {
        return this.httpClient.get<ProjectJobAttachment[]>(
            `/app-api/v1/projects/${projectId}/jobs/${formId}/attachments`
        );
    }
}
