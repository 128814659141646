
export function getFileNameFromContentDisposition(
    contentDisposition: string | null | undefined,
    defaultFileName: string
): string {
    if (!contentDisposition) {
        return defaultFileName;
    }

    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    const utf8Match = utf8FilenameRegex.exec(contentDisposition)

    if (utf8Match !== null && utf8Match[1]) {
        return decodeURIComponent(utf8Match[1]);
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        // slicing off everything before 'filename='
        const filenameStart = contentDisposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = contentDisposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches != null && matches[2]) {
                return matches[2];
            }
        }
    }

    return defaultFileName;
}
