import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {PaulaDatabaseService} from "../paula-database.service";
import {PaulaDatabase} from "../../models/paula-database";
import {AnyProjectJobForm} from '../../models/project-job-form';
import {getFormDatabaseIds} from '../../utils/get-form-database-ids';


export class PaulaDatabaseServiceMock extends AbstractCrudServiceMock<PaulaDatabase> implements PaulaDatabaseService {

    async downloadFormDatabases(forms: AnyProjectJobForm[]): Promise<void> {
        const ids = getFormDatabaseIds(forms);
        const promises = [...ids].map(id => this.get(id))
        await Promise.allSettled(promises)
    }

    get(id: number): Promise<PaulaDatabase> {
        const paulaDatabases: Array<PaulaDatabase> = [
            {
                id: 1,
                name: "Paula Database",
                records: [
                    {
                        name: "Record 1",
                        data: {
                            "key1": `value1_of_1`,
                            "key2": `value2_of_1`,
                            "key3": `value3_of_1`,
                        }
                    }
                ]
            },
            {
                id: 76,
                name: "Eten",
                records: [
                    {
                        name: "Banaan",
                        data: {
                            "Fruit": "Ja",
                            "Groente": "Nee",
                            "Soort": "Fruit",
                        }
                    },
                    {
                        name: "Sla",
                        data: {
                            "Fruit": "Nee",
                            "Groente": "Ja",
                            "Soort": "Groente",
                        }
                    }
                ]
            },
            {
                id: 75,
                name: "Soorten eten",
                records: [
                    {
                        name: "Fruit",
                        data: {
                            "Fruit": "Ja",
                            "Groente": "Nee",
                            "Vis": "Nee",
                        }
                    },
                    {
                        name: "Groente",
                        data: {
                            "Fruit": "Nee",
                            "Groente": "Ja",
                            "Vis": "Nee",
                        }
                    }
                ]
            }
        ]

        return Promise.resolve(paulaDatabases.filter(database => database.id === id)[0])
    }
}
