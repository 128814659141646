import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {WorkspaceConfigService} from '../services/workspace-config.service';
import {take} from 'rxjs/operators';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(
        private workspaceConfigService: WorkspaceConfigService,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Ignore requests that are not to the API
        if (!request.url.startsWith('/')) {
            return next.handle(request);
        }

        return this.workspaceConfigService.workspaceConfig$.pipe(
            take(1),
            switchMap(config => {
                if (config === null) {
                    throw new Error('No workspace config available');
                }

                const newReq = request.clone({
                    url: config.apiHost + request.url,
                });
                return next.handle(newReq)
            })
        )
    }
}
