import {inject, NgModule} from '@angular/core';
import {PreloadAllModules, provideRouter, Router, RouterModule, Routes, withPreloading,} from '@angular/router';
import {ensureAuthenticatedGuardFn} from './services/ensure-authenticated-guard.service';
import {CreatePaulaObjectComponent} from './components/create-paula-object/create-paula-object.component';
import {WorkspaceConfigService} from './services/workspace-config.service';
import {firstValueFrom} from 'rxjs';

const routes: Routes = [
    {
        path: 'workspace-select',
        loadChildren: () => import('./pages/workspace-select/workspace-select.module').then( m => m.WorkspaceSelectPageModule)
    },
    {
        path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule)
    },
    {
        path: 'authenticate', loadChildren: () => import('./pages/authenticate/authenticate.module').then(m => m.AuthenticatePageModule)
    },
    {
        path: 'projects',
        canActivateChild: [ensureAuthenticatedGuardFn],
        children: [
            {path: '', loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsPageModule) },
            {
                path: ':project/object/:object/jobs/new',
                loadChildren: () => import('./pages/add-project-job-for-object/add-project-job-for-object.module')
                    .then(m => m.AddProjectJobForObjectPageModule),
            },
            {
                path: ':project/object/new',
                component: CreatePaulaObjectComponent,
            },
            {
                path: ':project/jobs/new',
                loadChildren: () => import('./pages/add-project-job/add-project-job.module').then(m => m.AddProjectJobPageModule)
            },
            {
                path: ':project/jobs',
                loadChildren: () => import('./pages/project-jobs/project-jobs.module').then(m => m.ProjectJobsPageModule)
            },
            {
                path: ':project/map',
                loadChildren: () => import('./pages/project-map/project-map.module').then(m => m.ProjectMapPageModule)
            },
            {
                path: ':project/jobs/:job/layered',
                loadChildren: () => import('./pages/project-job-layered/project-job-layered.module').then(m => m.ProjectJobLayeredPageModule)
            },
            {
                path: ':project/jobs/:job/v2',
                loadChildren: () => import('./pages/project-job-form-v2/project-job-form-v2.module').then(m => m.ProjectJobFormV2PageModule)
            },
        ]
    },
    {
        path: '**',
        children: [],// Empty children as hack to make angular allow this route
        canActivate: [async () => {
            const router = inject(Router);
            const currentConfig = await firstValueFrom(inject(WorkspaceConfigService).workspaceConfig$);
            if (currentConfig === null) {
                // Redirect to workspace select page if no workspace is selected
                return router.createUrlTree(['/workspace-select']);
            } else {
                return router.createUrlTree(['/projects']);
            }
        }]
    },
];

@NgModule({
    imports: [],
    providers: [
        provideRouter(routes, withPreloading(PreloadAllModules)),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
