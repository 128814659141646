import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {CreatePaulaObjectDTO, PaulaObject} from '../../models/paula-object';
import {ProjectObjectService} from '../project-object.service';
import {ObjectQRSearchResponse} from "../../models/object-qr-search-response";

export class ProjectObjectServiceMock implements ProjectObjectService {
    private data: PaulaObject[] = [
        {
            id: 1,
            objectOmschrijvingKort: 'Beton',
            objectType: {id: 1},
            objectFilter: 'filter',
            objectSubFilter: null,
            objectGeo: null,
            objectId: 'Beton-001',
            objectXvan: 0,
            objectYvan: 0,
            objectStatus: true,
            objectLocationType: 'Linear'
        },
        {
            id: 2,
            objectOmschrijvingKort: 'Asfalt',
            objectType: {id: 1},
            objectFilter: 'filter',
            objectSubFilter: null,
            objectGeo: null,
            objectId: 'Asfalt-001',
            objectXvan: 0,
            objectYvan: 0,
            objectStatus: true,
            objectLocationType: 'Linear'
        },
    ];

    list(): Observable<PageResponse<PaulaObject>> {
        const result: PageResponse<PaulaObject> = {
            content: this.data,
            empty: false,
            first: true,
            last: true,
            number: 0,
            numberOfElements: this.data.length,
            size: 25,
            totalElements: this.data.length,
            totalPages: 1,
        };

        return of(result);
    }

    get(project: number, id: number): Observable<PaulaObject> {
        const item = this.data.find(item => item.id === id);
        if (item === undefined) {
            throw new Error('Not found');
        }

        return of(item);
    }

    search(): Observable<PageResponse<PaulaObject>> {
        const result: PageResponse<PaulaObject> = {
            content: this.data,
            empty: false,
            first: true,
            last: true,
            number: 0,
            numberOfElements: this.data.length,
            size: 25,
            totalElements: this.data.length,
            totalPages: 1
        };

        return of(result);
    }

    qr(): Observable<ObjectQRSearchResponse> {
        const result: ObjectQRSearchResponse = {
            notFoundForObjectType: true,
            hits: []
        };

        return of(result);
    }

    create(project: number, dto: CreatePaulaObjectDTO): Observable<PaulaObject> {
        const newObject: PaulaObject = {
            ...dto,
            id: this.data.length + 1,
            objectStatus: true,
            objectXvan: 0,
            objectYvan: 0,
            objectGeo: null,
            objectFilter: null,
            objectSubFilter: null,
        }
        this.data.push(newObject);

        return of(newObject);
    }
}
