{
    "name": "",
    "palette": [],
    "tools": [{
        "title": "Bestaande handhole",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIj4KICA8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIGZpbGw9IiMwMjc4ODYiLz4KPC9zdmc+",
        "identifier": "Bestaande handhole",
        "component": "square",
        "initialisationObject": {
            "fill": "#027886"
        }
    }, {
        "title": "Bestaande koppeling",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIj4KICA8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgZmlsbD0iIzAyNzg4NiIvPgo8L3N2Zz4K",
        "identifier": "Bestaande koppeling",
        "component": "circle",
        "initialisationObject": {
            "fill": "#027886"
        }
    }, {
        "title": "Bestaande buis",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCAxMCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjEwIj4KICA8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMTAiIGZpbGw9IiMwMjc4ODYiLz4KPC9zdmc+Cg==",
        "identifier": "Bestaande buis",
        "component": "pencil",
        "initialisationObject": {
            "stroke": "#027886",
            "stroke-width": 10
        }
    }, {
        "title": "Nieuwe handhole",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIj4KICA8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIGZpbGw9IiM5NzM4MjAiIHN0cm9rZT0iIzAyNzg4NiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=",
        "identifier": "Nieuwe handhole",
        "component": "square",
        "initialisationObject": {
            "stroke": "#027886",
            "stroke-width": 2,
            "fill": "#973820"
        }
    }, {
        "title": "Nieuwe koppeling",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIj4KICA8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgZmlsbD0iIzk3MzgyMCIgc3Ryb2tlPSIjMDI3ODg2IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==",
        "identifier": "Nieuwe koppeling",
        "component": "circle",
        "initialisationObject": {
            "stroke": "#027886",
            "stroke-width": 2,
            "fill": "#973820"
        }
    }, {
        "title": "Nieuw tracé",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCAxMCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjEwIj4KICA8cG9seWdvbiBwb2ludHM9IjQwIDUgMzMgMCAzMyAzIDAgMyAwIDcgMzMgNyAzMyAxMCA0MCA1IiBmaWxsPSIjOTczODIwIi8+Cjwvc3ZnPgo=",
        "identifier": "Nieuw tracé",
        "component": "arrow",
        "initialisationObject": {
            "stroke": "#973820",
            "strokeWidth": 5,
            "pointerLength": 5,
            "pointerWidth": 5
        }
    }, {
        "title": "Mantelbuis, persing of boring",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCAxMCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjEwIj4KICA8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMTAiIGZpbGw9IiM5NzM4MjAiLz4KPC9zdmc+Cg==",
        "identifier": "Mantelbuis, persing of boring",
        "component": "pencil",
        "initialisationObject": {
            "stroke": "#973820",
            "strokeWidth": 10
        }
    }, {
        "title": "Boring met mantelbuis",
        "icon": "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHJ5PSIyNy42NjE3MzkiIHJ4PSIyNy41OTgyOTUiIGlkPSJzdmdfMSIgY3k9IjI5LjU0Nzk1OCIgY3g9IjI5Ljg3MzEwNyIgZmlsbC1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9IiNmZmYiLz4KICA8dGV4dCBzdHlsZT0iY3Vyc29yOiBtb3ZlOyIgZm9udC13ZWlnaHQ9ImJvbGQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHRleHQtYW5jaG9yPSJzdGFydCIgZm9udC1mYW1pbHk9IidBcmlhbCwgc2Fucy1zZXJpZicsIEdhZGdldCwgc2Fucy1zZXJpZiIgZm9udC1zaXplPSIxOCIgaWQ9InN2Z181IiB5PSIzNS4yNTgwMjQiIHg9IjguMzY1Njg2IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+R21CPC90ZXh0PgogPC9nPgo8L3N2Zz4=",
        "identifier": "Boring met mantelbuis",
        "component": "icon",
        "initialisationObject": {
            "imageSource": "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHJ5PSIyNy42NjE3MzkiIHJ4PSIyNy41OTgyOTUiIGlkPSJzdmdfMSIgY3k9IjI5LjU0Nzk1OCIgY3g9IjI5Ljg3MzEwNyIgZmlsbC1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9IiNmZmYiLz4KICA8dGV4dCBzdHlsZT0iY3Vyc29yOiBtb3ZlOyIgZm9udC13ZWlnaHQ9ImJvbGQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHRleHQtYW5jaG9yPSJzdGFydCIgZm9udC1mYW1pbHk9IidBcmlhbCwgc2Fucy1zZXJpZicsIEdhZGdldCwgc2Fucy1zZXJpZiIgZm9udC1zaXplPSIxOCIgaWQ9InN2Z181IiB5PSIzNS4yNTgwMjQiIHg9IjguMzY1Njg2IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+R21CPC90ZXh0PgogPC9nPgo8L3N2Zz4=",
            "width": 40,
            "height": 40
        }
    }, {
        "title": "Boring zonder buis",
        "icon": "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHJ5PSIyNy42NjE3MzkiIHJ4PSIyNy41OTgyOTUiIGlkPSJzdmdfMSIgY3k9IjI5LjU0Nzk1OCIgY3g9IjI5Ljg3MzEwNyIgZmlsbC1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9IiNmZmYiLz4KICA8dGV4dCBmb250LXdlaWdodD0iYm9sZCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgdGV4dC1hbmNob3I9InN0YXJ0IiBmb250LWZhbWlseT0iJ0FyaWFsLCBzYW5zLXNlcmlmIiBmb250LXNpemU9IjE4IiBpZD0ic3ZnXzUiIHk9IjM2LjI3Mjg0NiIgeD0iMTEuNjYzODU4IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+R3pCPC90ZXh0PgogPC9nPgo8L3N2Zz4=",
        "identifier": "Boring zonder buis",
        "component": "icon",
        "initialisationObject": {
            "imageSource": "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHJ5PSIyNy42NjE3MzkiIHJ4PSIyNy41OTgyOTUiIGlkPSJzdmdfMSIgY3k9IjI5LjU0Nzk1OCIgY3g9IjI5Ljg3MzEwNyIgZmlsbC1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9IiNmZmYiLz4KICA8dGV4dCBmb250LXdlaWdodD0iYm9sZCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgdGV4dC1hbmNob3I9InN0YXJ0IiBmb250LWZhbWlseT0iJ0FyaWFsLCBzYW5zLXNlcmlmIiBmb250LXNpemU9IjE4IiBpZD0ic3ZnXzUiIHk9IjM2LjI3Mjg0NiIgeD0iMTEuNjYzODU4IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+R3pCPC90ZXh0PgogPC9nPgo8L3N2Zz4=",
            "width": 40,
            "height": 40
        }
    }, {
        "title": "Persing",
        "icon": "data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPCEtLSBDcmVhdGVkIHdpdGggTWV0aG9kIERyYXcgLSBodHRwOi8vZ2l0aHViLmNvbS9kdW9waXhlbC9NZXRob2QtRHJhdy8gLS0+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGVsbGlwc2Ugc3Ryb2tlPSIjOTczODIwIiByeT0iMjcuNjYxNzM5IiByeD0iMjcuNTk4Mjk1IiBpZD0ic3ZnXzEiIGN5PSIyOS44MDE2NjQiIGN4PSIyOS44NzMxMDciIGZpbGwtb3BhY2l0eT0ibnVsbCIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSIjZmZmIi8+CiAgPHRleHQgZm9udC13ZWlnaHQ9ImJvbGQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHRleHQtYW5jaG9yPSJzdGFydCIgZm9udC1mYW1pbHk9IkFyaWFsLCBzYW5zLXNlcmlmIiBmb250LXNpemU9IjMyIiBpZD0ic3ZnXzUiIHk9IjQwLjMzMjEzNCIgeD0iMjAuMTE4NTc3IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+UDwvdGV4dD4KIDwvZz4KPC9zdmc+",
        "identifier": "Persing",
        "component": "icon",
        "initialisationObject": {
            "imageSource": "data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPCEtLSBDcmVhdGVkIHdpdGggTWV0aG9kIERyYXcgLSBodHRwOi8vZ2l0aHViLmNvbS9kdW9waXhlbC9NZXRob2QtRHJhdy8gLS0+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGVsbGlwc2Ugc3Ryb2tlPSIjOTczODIwIiByeT0iMjcuNjYxNzM5IiByeD0iMjcuNTk4Mjk1IiBpZD0ic3ZnXzEiIGN5PSIyOS44MDE2NjQiIGN4PSIyOS44NzMxMDciIGZpbGwtb3BhY2l0eT0ibnVsbCIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSIjZmZmIi8+CiAgPHRleHQgZm9udC13ZWlnaHQ9ImJvbGQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHRleHQtYW5jaG9yPSJzdGFydCIgZm9udC1mYW1pbHk9IkFyaWFsLCBzYW5zLXNlcmlmIiBmb250LXNpemU9IjMyIiBpZD0ic3ZnXzUiIHk9IjQwLjMzMjEzNCIgeD0iMjAuMTE4NTc3IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+UDwvdGV4dD4KIDwvZz4KPC9zdmc+",
            "width": 40,
            "height": 40
        }
    }, {
        "title": "Leggen mantelbuis",
        "icon": "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHJ5PSIyNy42NjE3MzkiIHJ4PSIyNy41OTgyOTUiIGlkPSJzdmdfMSIgY3k9IjI5LjgwMTY2NCIgY3g9IjI5Ljg3MzEwNyIgZmlsbC1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9IiNmZmYiLz4KICA8dGV4dCBmb250LXdlaWdodD0iYm9sZCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgdGV4dC1hbmNob3I9InN0YXJ0IiBmb250LWZhbWlseT0iQXJpYWwsIHNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMzIiIGlkPSJzdmdfNSIgeT0iNDAuMzMyMTM0IiB4PSIyMC4xMTg1NzciIHN0cm9rZS13aWR0aD0iMCIgc3Ryb2tlPSIjOTczODIwIiBmaWxsPSIjOTczODIwIj5MPC90ZXh0PgogPC9nPgo8L3N2Zz4=",
        "identifier": "Leggen mantelbuis",
        "component": "icon",
        "initialisationObject": {
            "imageSource": "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHJ5PSIyNy42NjE3MzkiIHJ4PSIyNy41OTgyOTUiIGlkPSJzdmdfMSIgY3k9IjI5LjgwMTY2NCIgY3g9IjI5Ljg3MzEwNyIgZmlsbC1vcGFjaXR5PSJudWxsIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9IiNmZmYiLz4KICA8dGV4dCBmb250LXdlaWdodD0iYm9sZCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgdGV4dC1hbmNob3I9InN0YXJ0IiBmb250LWZhbWlseT0iQXJpYWwsIHNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMzIiIGlkPSJzdmdfNSIgeT0iNDAuMzMyMTM0IiB4PSIyMC4xMTg1NzciIHN0cm9rZS13aWR0aD0iMCIgc3Ryb2tlPSIjOTczODIwIiBmaWxsPSIjOTczODIwIj5MPC90ZXh0PgogPC9nPgo8L3N2Zz4=",
            "width": 40,
            "height": 40
        }
    }, {
        "title": "Boog boring",
        "icon": "data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPCEtLSBDcmVhdGVkIHdpdGggTWV0aG9kIERyYXcgLSBodHRwOi8vZ2l0aHViLmNvbS9kdW9waXhlbC9NZXRob2QtRHJhdy8gLS0+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGVsbGlwc2Ugc3Ryb2tlPSIjOTczODIwIiByeT0iMjcuNjYxNzM5IiByeD0iMjcuNTk4Mjk1IiBpZD0ic3ZnXzEiIGN5PSIyOS44MDE2NjQiIGN4PSIyOS44NzMxMDciIGZpbGwtb3BhY2l0eT0ibnVsbCIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSIjZmZmIi8+CiAgPHRleHQgZm9udC13ZWlnaHQ9ImJvbGQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHRleHQtYW5jaG9yPSJzdGFydCIgZm9udC1mYW1pbHk9IkFyaWFsLCBzYW5zLXNlcmlmIiBmb250LXNpemU9IjI0IiBpZD0ic3ZnXzUiIHk9IjM3LjI4NzY2OCIgeD0iMTMuMjY4NTI4IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+QkI8L3RleHQ+CiA8L2c+Cjwvc3ZnPg==",
        "identifier": "Boog boring",
        "component": "icon",
        "initialisationObject": {
            "imageSource": "data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPCEtLSBDcmVhdGVkIHdpdGggTWV0aG9kIERyYXcgLSBodHRwOi8vZ2l0aHViLmNvbS9kdW9waXhlbC9NZXRob2QtRHJhdy8gLS0+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGVsbGlwc2Ugc3Ryb2tlPSIjOTczODIwIiByeT0iMjcuNjYxNzM5IiByeD0iMjcuNTk4Mjk1IiBpZD0ic3ZnXzEiIGN5PSIyOS44MDE2NjQiIGN4PSIyOS44NzMxMDciIGZpbGwtb3BhY2l0eT0ibnVsbCIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSIjZmZmIi8+CiAgPHRleHQgZm9udC13ZWlnaHQ9ImJvbGQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHRleHQtYW5jaG9yPSJzdGFydCIgZm9udC1mYW1pbHk9IkFyaWFsLCBzYW5zLXNlcmlmIiBmb250LXNpemU9IjI0IiBpZD0ic3ZnXzUiIHk9IjM3LjI4NzY2OCIgeD0iMTMuMjY4NTI4IiBzdHJva2Utd2lkdGg9IjAiIHN0cm9rZT0iIzk3MzgyMCIgZmlsbD0iIzk3MzgyMCI+QkI8L3RleHQ+CiA8L2c+Cjwvc3ZnPg==",
            "width": 40,
            "height": 40
        }
    }, {
        "title": "Invoer / doorvoer brandwerend",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHN0cm9rZS1kYXNoYXJyYXk9IjEwLDEwLDEwLDEwLDEwLDEwLDEwLDEwLDEwLDgiIHJ5PSIyNC42MTcyNzMiIHJ4PSIyNC45MzQzODgiIGlkPSJzdmdfMSIgY3k9IjI5LjgwMTY2NCIgY3g9IjI5Ljk5OTk1OSIgZmlsbC1vcGFjaXR5PSIwIiBzdHJva2Utd2lkdGg9IjciLz4KIDwvZz4KPC9zdmc+",
        "identifier": "Invoer / doorvoer brandwerend",
        "component": "icon",
        "initialisationObject": {
            "imageSource": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CiA8IS0tIENyZWF0ZWQgd2l0aCBNZXRob2QgRHJhdyAtIGh0dHA6Ly9naXRodWIuY29tL2R1b3BpeGVsL01ldGhvZC1EcmF3LyAtLT4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8ZWxsaXBzZSBzdHJva2U9IiM5NzM4MjAiIHN0cm9rZS1kYXNoYXJyYXk9IjEwLDEwLDEwLDEwLDEwLDEwLDEwLDEwLDEwLDgiIHJ5PSIyNC42MTcyNzMiIHJ4PSIyNC45MzQzODgiIGlkPSJzdmdfMSIgY3k9IjI5LjgwMTY2NCIgY3g9IjI5Ljk5OTk1OSIgZmlsbC1vcGFjaXR5PSIwIiBzdHJva2Utd2lkdGg9IjciLz4KIDwvZz4KPC9zdmc+",
            "width": 40,
            "height": 40
        }
    }, {
        "title": "Vrij tekstveld",
        "icon": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCAyNiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjI2Ij4KICA8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMjYiIGZpbGw9IiNmZmYiLz4KICA8cGF0aCBkPSJNMzgsMlYyNEgyVjJIMzhtMi0ySDBWMjZINDBWMFoiIGZpbGw9IiM5NzM4MjAiLz4KICA8Y2lyY2xlIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgY3g9IjEwIiBjeT0iMTUiIHI9IjIiIHN0cm9rZT0iIzAwMzg0YyIgc3Ryb2tlLXdpZHRoPSIwIiBmaWxsPSIjMDAzODRjIi8+IAogIDxjaXJjbGUgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjeD0iMjAiIGN5PSIxNSIgcj0iMiIgc3Ryb2tlPSIjMDAzODRjIiBzdHJva2Utd2lkdGg9IjAiIGZpbGw9IiMwMDM4NGMiLz4KICA8Y2lyY2xlIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgY3g9IjMwIiBjeT0iMTUiIHI9IjIiIHN0cm9rZT0iIzAwMzg0YyIgc3Ryb2tlLXdpZHRoPSIwIiBmaWxsPSIjMDAzODRjIi8+Cjwvc3ZnPg==",
        "identifier": "Vrij tekstveld",
        "component": "text",
        "initialisationObject": {
            "backgroundRect": {
                "fill": "#FFFFFF",
                "stroke": "#973820",
                "strokeWidth": 4,
                "padding": [5, 5, 5, 5]
            },
            "textConfig": {
                "fontStyle": "bold",
                "fill": "#00384c"
            }
        }
    }]
}
