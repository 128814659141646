import {AnyProjectJobForm} from '../models/project-job-form';

export function getFormDatabaseIds(forms: AnyProjectJobForm[]): Set<number> {
    const ids = new Set<number>();

    for (const form of forms) {
        if (form.type === 'jobForm') {
            for (const chapter of form.chapters) {
                for (const question of chapter.questions) {
                    if (question.type === "database") {
                        ids.add(question.config.database)
                    }
                }
            }
        } else {
            for (const layer of form.layers) {
                if (layer.type === 'databaseLayer') {
                    ids.add(layer.databaseId)
                    for (const question of layer.questions) {
                        if (question.type === "database") {
                            ids.add(question.config.database)
                        }
                    }
                } else if (layer.type === 'subtypeLayer') {
                    for (const subtype of layer.subtypes) {
                        for (const question of subtype.questions) {
                            if (question.type === "database") {
                                ids.add(question.config.database)
                            }
                        }
                    }
                }
            }
        }
    }

    return ids
}
